import { createAsyncThunk } from '@reduxjs/toolkit';
import { DELIVERY_SETTINGS_REDUCER } from 'store/name';
import customAxios from 'utils/custom-axios';
import Language from 'utils/language';
import deliverySettingsApi from '../../../rtk-query/services/delivery-settings';
import { DELIVERY_SETTINGS_TAGS } from '../../../rtk-query/tags';
export const getDeliverySettingsListPaged = createAsyncThunk(`${DELIVERY_SETTINGS_REDUCER}/getDeliverySettingsListPaged`, async (_, { rejectWithValue }) => {
    try {
        const response = await customAxios.get('/automatic-delivery-setting');
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getDeliverySettingsInfoById = createAsyncThunk(`${DELIVERY_SETTINGS_REDUCER}/getDeliverySettingsInfoById`, async (id, { rejectWithValue }) => {
    try {
        const response = await customAxios.get(`/automatic-delivery-setting/${id}`);
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const deleteDeliverySettings = createAsyncThunk(`${DELIVERY_SETTINGS_REDUCER}/deleteDeliverySettings`, async (deliverySettingsId, { rejectWithValue, dispatch }) => {
    try {
        await customAxios.delete(`/automatic-delivery-setting/delete/${deliverySettingsId}`);
        dispatch(deliverySettingsApi.util.invalidateTags([DELIVERY_SETTINGS_TAGS.GET_REGISTERED_INTENSITY_SETTINGS]));
        return deliverySettingsId;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const deleteMultiDeliverySettings = createAsyncThunk(`${DELIVERY_SETTINGS_REDUCER}/deleteMultiDeliverySettings`, async (data, { rejectWithValue, dispatch }) => {
    try {
        await customAxios.post(`/automatic-delivery-setting/delete-bulk`, data);
        dispatch(deliverySettingsApi.util.invalidateTags([DELIVERY_SETTINGS_TAGS.GET_REGISTERED_INTENSITY_SETTINGS]));
        return data.ItemsToDelete;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const getEarthquakeIntensity = createAsyncThunk(`${DELIVERY_SETTINGS_REDUCER}/getEarthquakeIntensity`, async (_, { rejectWithValue }) => {
    try {
        const response = await customAxios.get('/automatic-delivery-setting/get-earthquake-intensity');
        return response.data;
    }
    catch {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const addDeliverySettings = createAsyncThunk(`${DELIVERY_SETTINGS_REDUCER}/addDeliverySettings`, async (deliverySettingsData, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.post('/automatic-delivery-setting/create', deliverySettingsData);
        dispatch(deliverySettingsApi.util.invalidateTags([DELIVERY_SETTINGS_TAGS.GET_REGISTERED_INTENSITY_SETTINGS]));
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
export const updateDeliverySettings = createAsyncThunk(`${DELIVERY_SETTINGS_REDUCER}/ updateDeliverySettings`, async (deliverySettingsData, { rejectWithValue, dispatch }) => {
    try {
        const response = await customAxios.post('/automatic-delivery-setting/update', deliverySettingsData);
        dispatch(deliverySettingsApi.util.invalidateTags([DELIVERY_SETTINGS_TAGS.GET_REGISTERED_INTENSITY_SETTINGS]));
        return response.data;
    }
    catch (e) {
        return rejectWithValue(Language.jap.CommonErrorMsg);
    }
});
